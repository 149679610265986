<script>
import UserServiceApi from "@/service/api/users"

export default {
  props: ["userData"],
  data() {
    return {
      toggling :false
    };
  },
  methods:{
    toggleUserActive(){
      this.toggling = true;
      UserServiceApi.deleteUser({
        user_id:this.userData.id,
        action:"delete"
      }).then((response)=>{
        console.log(response.data)
         this.$emit("userUpdated")
      }).finally(()=>{
        this.toggling = false;
      })
    }
  }
};
</script>

<template>
  <div class="card disable-user" v-if="userData">
 
    <div class="m-auto">
      <button class="btn btn-danger" @click="toggleUserActive" v-if="userData.status==='active'">
       <span v-if="toggling">Disabling...</span> <span v-else>Disable</span> {{ userData.name }}
      </button>
      <button @click="toggleUserActive" class="btn btn-success" v-else><span v-if="toggling">Enabling...</span> <span v-else>Enable</span> {{ userData.name }}</button>
    </div>
    <div class="mb-5">
      <span v-if="!userData.status==='active'">
        *** {{ userData.name }} no longer able to participate in sheesh
        activities
      </span>
      <span v-else>
        *** Currently {{ userData.name }} is able to participate in sheesh
        activities
      </span>
    </div>
  </div>
</template>

<style scoped>
.disable-user {
  height: 400px;
  text-align: center;
}
</style>
