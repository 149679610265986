import axios from 'axios';


axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) =>{
    switch("error",error.response.status){
        case 401:
            window.localStorage.removeItem("user");
            window.localStorage.removeItem("token");
            break
        default:
            Promise.reject(error)
            
    }
    }

);

export default axios;
